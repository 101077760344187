/**
 * Tables
 */
/*------------------------------------*\
	#TABLE
\*------------------------------------*/
/**
 * 1) Data Table
 * 2) Force all tables to be at least a certain width so table cells don't collapse into
 *    unreadable chunks.
 */
 .em-c-table {
  margin-bottom: 1rem;
  border: 1px solid #cccccc;
  min-width: 600px;
  table-layout: auto;
  
  /* 2 */
  /**
   * Link within a table
   * 1) Used this way to add these styles to any link in table, whether the link has a class or not
   * 2) CSS should NOT be laid out this way unless targeting arbitrary text AND text with classes
   */
  /**
   * Table within table object
   * 1) Margin bottom removed when table is inside table object
   */
  /**
   * Table within simple table object
   * 1) Min width of 0 added so table sits within the viewport width at smaller screens
   */ }
  .em-c-table a {
    color: #0c69b0;
    text-decoration: underline;
    transition: color 0.15s ease;
    		/**
             * Theming
             * 1) Apply theme color when theme class is added to the
             *    `body` (i.e. `<body class="em-theme--blue">`) or if a
             *    modifier is added to the parent block
             */ }
    .em-theme--blue .em-c-table a, .em-c-table--theme-blue .em-c-table a {
      color: #0c69b0;
      /* 1 */ }
      .em-theme--blue .em-c-table a:hover, .em-theme--blue .em-c-table a:focus, .em-c-table--theme-blue .em-c-table a:hover, .em-c-table--theme-blue .em-c-table a:focus {
        color: #3a397b; }
    .em-theme--red .em-c-table a, .em-c-table--theme-red .em-c-table a {
      color: #0c69b0; }
      .em-theme--red .em-c-table a:hover, .em-theme--red .em-c-table a:focus, .em-c-table--theme-red .em-c-table a:hover, .em-c-table--theme-red .em-c-table a:focus {
        color: #111122; }
    .em-c-table a:hover {
      color: #111122; }
    .em-c-table a:focus {
      outline: 1px dotted #0c69b0; }
    .em-c-table a:active {
      background: none; }
  .em-c-table-object .em-c-table {
    margin-bottom: 0; }
  .em-c-table-object--simple .em-c-table {
    min-width: 0; }

/**
 * Table Header
 */
.em-c-table__header {
  background: #111122;
  color: #fff; 
}

/**
 * Table Header
 */
.em-c-table__header-cell {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  font-size: 0.75rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid #9b9b9b;
  /*
   * Table header cell within condensed table
   */ }
  .em-c-table--condensed .em-c-table__header-cell {
    padding: .375rem .8rem;
    font-size: .675rem; 
  }

/**
 * Table Row
 * 1) Position relative and margin bottom of -1px added to overlap active border over inactive border
 */
.em-c-table__row {
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  transition: all 0.15s ease;
  /**
   * Highlighted row when selected
   */ }
  .em-c-table__row:hover, .em-c-table__row:focus {
    background: rgba(0, 0, 0, 0.04); }
  .em-c-table__row.em-is-active {
    background: #e1efff;
    z-index: 1; }
  .em-c-table--striped .em-c-table__row {
    border: 0; }
    .em-c-table--striped .em-c-table__row:hover {
      background: none;
      box-shadow: inset 0px 1px 0px 0px #cccccd, inset 0px -1px 0px 0px #cccccd; }
    .em-c-table--striped .em-c-table__row:nth-child(even) {
      background: #f5f5f5; }
  .em-c-table--striped .em-c-table__row {
    border: 0; }
    .em-c-table--striped .em-c-table__row:hover {
      background: none;
      box-shadow: inset 0px 1px 0px 0px #cccccd, inset 0px -1px 0px 0px #cccccd; }
    .em-c-table--striped .em-c-table__row:nth-child(even) {
      background: #f5f5f5; }
  .em-c-table__row.em-js-table-row-draggable {
    position: relative;
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -webkit-grab; }
  .em-c-table__row.em-is-dragging {
    cursor: grabbing;
    cursor: -webkit-grabbing; }

/**
 * Table Cell
 */
.em-c-table__cell {
  padding: 1rem 0.8rem;
  white-space: nowrap;
  /**
   * Table cell within condensed table
   */
  /**
   * Table cell within secondary table row
   */
  /**
   * Table cell that is also a dropdown
   */
  /**
   * Table cell that has padding
   * 1) Lines up text between cell with icon and cell without
   */ }
  .em-c-table--condensed .em-c-table__cell {
    padding: 0.25rem .8rem;
    font-size: .75rem; }
  .em-c-table__row--secondary .em-c-table__cell {
    /**
     * First table cell within secondary table row
     */ }
    .em-c-table__row--secondary .em-c-table__cell:first-child {
      padding-left: 2.7rem; }
  .em-c-table__cell--dropdown {
    cursor: pointer; }
  .em-c-table__cell--padding {
    padding-left: 2.7rem; }
  .em-c-table__cell.em-js-cell-editable:focus {
    outline: 0;
    border: 1px solid #0c69b0; }
  .em-js-table-row-draggable .em-c-table__cell:last-child {
    position: relative; }
    .em-js-table-row-draggable .em-c-table__cell:last-child:after {
      content: "\0022EE";
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      height: calc(100% - 1.6rem);
      width: 20px;
      padding-top: 1.6rem;
      background: #f5f5f5;
      font-family: "EMprintBold", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal; }

/**
 * Table Footer
 */
.em-c-table__footer {
  font-size: 0.75rem; }

/**
 * Table Footer Cell
 */
.em-c-table__footer-cell {
  padding: 0.8rem;
  /*
   * Table footer cell within condensed table
   */ }
  .em-c-table--condensed .em-c-table__footer-cell {
    padding: .375rem .8rem;
    font-size: .675rem; }

/*------------------------------------*\
	#COLLAPSIBLE TABLE
\*------------------------------------*/
/**
 * Table header cell
 */
.em-c-table__header-cell {
  white-space: nowrap;
  /**
   * Table header cell within collapsible table object
   */ }
  .em-c-table-object--collapsible .em-c-table__header-cell {
    /**
     * First table header cell in collapsible table object
     * 1) Padding added to align text
     */ }
    .em-c-table-object--collapsible .em-c-table__header-cell:first-child {
      padding-left: 2.7rem; }

/**
 * Secondary table row
 */
.em-c-table__row--secondary {
  display: none;
  /**
   * Secondary row is visible
   * 1) When JS adds em-is-visible links display.
   */ }
  .em-c-table__row--secondary.em-is-visible {
    display: table-row; }

/**
 * Table icon
 * 1) Used for the table rows that can dropdown
 */
.em-c-table__icon {
  position: relative;
  top: 2px;
  margin-right: 1rem;
  color: #111122;
  fill: #111122;
  /**
   * Open table row
   * 1) When table row is open, rotate icon 180 degrees
   */ }
  .em-c-table__row.em-is-open .em-c-table__icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    /* 1 */ }

/*------------------------------------*\
    #TABLE OBJECT
\*------------------------------------*/
/**
 * 1) A table object is a data table with accompanying toolbars
 */
.em-c-table-object {
  margin-bottom: 2rem; }

/**
 * Table object body
 */
.em-c-table-object__body {
  position: relative; }

/**
 * Table Object Inner Wrapper
 * 1) Applies an overflow onto the table object body for small screen users to scroll over
 */
.em-c-table-object__body-inner {
  max-height: 700px;
  overflow-x: auto;
  overflow-y: auto;
  background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) right, linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, transparent 70%), linear-gradient(to left, rgba(0, 0, 0, 0.2) 0%, transparent 70%) right;
  background-repeat: no-repeat;
  background-size: 10px 100%, 10px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
  border-radius: 5px;
}
.loading {
  opacity: 0.2;
}

.table-actions {
  flex-wrap: wrap;
  margin: 5px;
}
.table-actions input {
  border-radius: 8px;
  border: 2px var(--black) solid;
  border-right: 1px var(--black) solid;
  padding: 3px 8px;
  height: 35px;
  margin: 5px 30px 5px 0px;
  width: 300px !important;

}

.table-actions select {
  color: var(--black);
  border-radius: 8px;
  border: 2px var(--black) solid;
  padding: 5px;
  height: 35px;
  margin: 6px 0 6px 20px;
  width: 300px !important;

}