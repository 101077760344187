.wrapper {
  margin: 0 !important;
  padding: 0 !important;
}

label {
  font-size: 17px;
  font-weight: 600;
  margin: 0 !important;
  padding: 0 !important;
}

input {
  font-size: 15px;
  padding: 0px 15px;
  height: 35px;
  border: 1px solid var(--lightgrey);
  border-radius: 10px;
  width: 400px;
  margin: 0 5px;
}

textarea {
  font-size: 15px;
  padding: 0px 15px;
  border: 1px solid var(--lightgrey);
  border-radius: 10px;
  width: 500px;
  margin: 0 5px;
}

input:focus-visible {
  outline: none;
  background-color: var(--lightgrey);
}

input.invalid {
  border: 1px solid var(--red);
}

p {
  color: var(--black) !important;
  font-size: 14px;
  margin-bottom: 0.4rem;
}

.alert {
  color: var(--red) !important;
  font-size: 14px;
  font-weight: 600;
  z-index: -1;
  margin: 0 0 0 15px !important;
  padding: 0 !important;
}

.container-for-searchtip {
  background-color: none;
  margin: 0 12px;
  height: 1px;
  position: relative;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-box-outer,
.search-box-inner {
  height: 0;
  overflow: hidden;
}
input:focus + div .search-box-outer,
.search-box-outer:hover {
  position: absolute;
  height: 250px;
  width: 100%;
  overflow: hidden;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: var(--darkgrey) solid 2px;
  border-top: none;
  z-index: 10;
}
input:focus + div .search-box-inner,
.search-box-inner:hover {
  overflow: auto;
  height: 250px;
}
.search-box-inner::-webkit-scrollbar {
  width: 10px;
}
.search-box-inner::-webkit-scrollbar-track {
  background-color: #d8d8d8;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.45);
}
.search-box-inner::-webkit-scrollbar-thumb {
  background-color: var(--darkgrey);
  border-radius: 20px;
  border: 3px solid #d8d8d8;
}

.entry-card {
  border-bottom: 1px solid var(--lightgrey);
  padding: 6px;
  cursor: pointer;
}
.entry-card:hover {
  background-color: var(--lightgrey);
}
.entry-mail {
  font-size: 15px;
  color: #4e4e4e;
  margin: 6px;
}
.entry-name {
  font-size: 13px;
  color: #636363db;
  margin: 6px 15px;
}

.recorder-list-item {
  position: relative;
  background: #3190d9;
  color: #fff;
  padding: 10px 15px;
  transition: 0.3s;
  align-items: center;
  border-radius: 4px;
  box-shadow: 2px 2px 8px rgb(121 121 121 / 77%);
  flex-grow: 1;
  margin-bottom: 0.2rem;
  margin-top: 0.8rem;
  margin-right: 0.5rem;
  width: 350px;
  height: 70px;
}

.recorder-list {
  margin: 1rem 0;
  padding: 2rem 0;
  border-bottom: 1px var(--lightgrey) solid;
  border-top: 1px var(--lightgrey) solid;
}

.fit-content {
  width: fit-content;
  margin: 0 !important;
  padding: 0 !important;
  margin-left: 0.5rem !important;
}

.clear-list-btn {
  height: 35px;
  background-color: white;
  color: #3190d9;
  border: #3190d9 solid 2px;
  border-radius: 5px;
  padding: 2px 15px;
  width: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 0.5rem !important;
}

.recorder-header {
  margin-bottom: 0.4rem;
}

.request-component {
  margin: 8px 15px;
  padding: 0 !important;
}

.BulkSearch-container-for-searchtip {
  background-color: none;
  position: relative;
}

.BulkSearch-box-list {
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
  height: 250px;
  width: 100%;
  width: 400px;
  overflow: auto;
  list-style-type: none;
  padding: 0;
  border-style: solid;
  border: 0px;
  border-radius: 10px;
  background-color: var(--white);
  margin: 0;
  cursor: pointer;
  z-index: 2;
}

.BulkSearch-li {
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  list-style-type: none; /* Remove bullets */
}

.BulkSearch-li:hover {
  opacity: 0.8;
  background-color: var(--primary-blue-light);
  border-radius: 6px;
  padding: 1rem 1rem;
}

button {
  background-color: red;
}

.bulk-btn {
  width: 140px;
  height: 45px;
  background-color: var(--primary-blue);
  color: var(--white);
  border: var(--primary-blue) solid 2px;
  border-radius: 5px;
  padding: 2px 15px;
  margin: 15px;
  font-size: 18px;
  font-weight: 600;
}

.bulk-btn:hover {
  color: var(--primary-blue);
  background-color: var(--white);
}

.remove-recorder-btn {
  position: absolute;
  bottom: 28px;
  left: 308px;
  background-color: inherit;
  width: 2rem;
  height: 2rem;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-recorder-btn:hover {
  color: red;
  border: black solid 1px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
