.IDMSync-container {
  background-color: var(--primary-white-light);
  padding: var(--content-padding);
  border-radius: var(--content-border-radius);
  margin: var(--content-margin);
  box-shadow: var(--box-shadow-main);
  min-height: 35rem;
}

  .IDMSync-horizontal_line {
    background-color: var(--lightgrey);
    height: 1px;
  }

  .IDMSync-description {
    text-align: start;
  }
  
  #PhoneHarvest-btn-return:hover {
    color: red;
    border: black solid 1px;
    border-radius: 5px;
  }

  .IDMSync-loading{
    opacity: 0.2;
  }

  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }