/* DEFAULT CSS VARIABLES */
:root {
  /* DEFAULT COLOR */
  --primary-blue: #007bff;
  --primary-blue-dark: rgba(0, 123, 255, 0.4);
  --primary-blue-mid: rgba(0, 123, 255, 0.2);
  --primary-blue-light: rgba(0, 123, 255, 0.08);
  --primary-white-light: rgba(252, 252, 255, 1);

  --lightgrey: lightgrey;
  --primary-grey-light: rgba(128, 128, 128, 0.15);
  --darkgrey: #0000009e;
  --white: #ffffff;
  --black: #3b3b3b;
  --red: rgb(201, 9, 9);

  /* DEFAULT MEASURES */
  --max-width: 1140px;
  --content-margin: 35px;
  --content-padding: 25px 40px;
  --content-border-radius: 15px;

  /* DEFAULT STYLES */
  --box-shadow-main: 0px 0px 15px -6px var(--black);
}

/*  */
/* STYLES TO REMOVE AFTER HOME PAGE IS SET*/
/*  */
a {
  color: var(--primary-blue);
}

code {
  color: var(--red);
}

p,
li,
h1 {
  color: var(--black);
}

/*  */
/* GLOBAL STYLES FOR DEFAULT ELEMENTS */
/*  */
* {
  box-sizing: border-box;
}

body {
  color: var(--black);
}

.container {
  max-width: var(--max-width);
}

select {
  outline: none !important;
}

ul {
  margin: 0 35px 15px 10px;
  text-align: justify;
}

/*  */
/* UTILITY CLASSES FOR CSS */
/*  */
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.App-em-c-alert {
  display: -ms-flexbox;
  display: flex;
  padding: 1em;
  margin-bottom: 1rem;
  background: #d3f2ff;
  border: 1px solid #0192d0;
}

.App-em-c-alert--success {
  background: #d4f3e6;
  border-color: #00b04f;
  color: #00b04f;
}

.App-em-c-alert--warning {
  background: #fffecf;
  border-color: #ffd700;
}

.App-em-c-alert--error {
  background: #fdded8;
  border-color: #b12a0b;
  color: #b12a0b;
}

.App-em-c-alert__actions {
  margin-left: 1rem;
}

.App-em-c-alert__body {
  -ms-flex: 1;
  flex: 1;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial",
    sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.875rem;
}

.App-em-c-text-btn {
  color: var(--primary-blue);
  background: none;
  border: 0;
  padding: 0;
  text-decoration: underline;
  font-size: 0.875rem;
  transition: all 0.15s ease;
  font-family: "EMprintSemibold", "HelveticaNeue", "Helvetica", "Arial",
    sans-serif;
  font-weight: 600;
  font-style: normal;
}

.App-btn {
  background-color: var(--primary-blue);
  color: var(--white);
  border: var(--primary-blue) solid 2px;
  padding: 10px 50px;
  margin: 15px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.5s;
  box-shadow: 2px 2px 5px #0000008c;
  border-radius: 4px;
  width: 163px;
  height: 51px;
}

.App-btn-small {
  font-size: 16px;
  width: 4rem;
  height: 2.5rem;
  padding: 5px;
  margin: 5px;
  text-align: center;
}

.App-btn-large {
  background-color: var(--primary-blue);
  color: var(--white);
  border: var(--primary-blue) solid 2px;
  font-size: 16px;
  width: 180px;
  height: 51px;
  padding: 10px 10px;
  text-align: center;
  margin: 8px 15px;
}

.App-btn-medium {
  background-color: var(--primary-blue);
  color: var(--white);
  border: var(--primary-blue) solid 2px;
  font-size: 16px;
  width: 130px;
  height: 51px;
  padding: 10px 10px;
  text-align: center;
  margin: 8px 15px;
  font-family: "EMprintRegular", "HelveticaNeue", "Helvetica", "Arial",
    sans-serif;
  font-weight: 500;
  font-style: normal;
}

.App-btn:hover {
  color: var(--primary-blue);
  background-color: var(--white);
  box-shadow: 2px 2px 5px var(--primary-blue);
}

.App-horizontal-line {
  background-color: var(--lightgrey);
  height: 1px;
}
