.ZoomOnboardSiteForm-container{
  background-color: var(--primary-white-light);
  padding: var(--content-padding);
  border-radius: var(--content-border-radius);
  margin: var(--content-margin);
  box-shadow: var(--box-shadow-main);
  min-height: 35rem;

}

p,
li,
h1 {
  color: var(--black);
}

h3 {
font-size: 1.5rem;
line-height: 1.2;
margin-bottom: 0.4rem; 
margin-top: 1rem; 
}

.loading {
  opacity: 0.2;
}

.ZoomOnboardSiteForm-header{
  margin-bottom: 3rem;
}