.RecordingExceptionForm-container {
  background-color: var(--primary-white-light);
  padding: var(--content-padding);
  border-radius: var(--content-border-radius);
  margin: var(--content-margin);
  box-shadow: var(--box-shadow-main);
}

p,
li,
h1 {
  color: var(--black);
}

ol > div > li::marker {
  font-weight: bold;
}

.RecordingExceptionForm-button {
  background-color: var(--primary-blue);
  color: var(--white);
  border: var(--primary-blue) solid 2px;
  padding: 10px 50px;
  margin: 15px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.5s;
  box-shadow: 2px 2px 5px #0000008c;
  width: 163px;
  height: 51px;
}

.RecordingExceptionForm-button:hover {
  color: var(--primary-blue);
  background-color: var(--white);
  box-shadow: 2px 2px 5px var(--primary-blue);
}

.inactive,
.inactive:hover {
  background-color: var(--primary-blue);
  color: var(--white);
  cursor: progress !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-container {
  align-items: center;
  justify-content: flex-start;
}
.message {
  display: inline-block;
  font-weight: 600;
}

.invalid {
  color: var(--red);
}
.valid {
  color: var(--primary-blue);
}

.access-date-message {
  padding: 0px 15px;
  margin: 0px 5px;
  margin-top: -30px;
}

.checkbox {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-left: 30px;

}

.checkbox-item {
  margin: 0px;
  padding: 0px;
}

.input-textarea {
  margin-bottom: 70px;
}

.loading {
  opacity: 0.1;
}

.list-content{
  padding-left: 30px;
}

.RecordingExceptionForm-description-text{
  text-align: justify;
}