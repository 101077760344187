label {
  font-size: 17px;
  font-weight: 600;
  margin: 8px 15px;
}

.SelectInput-button {
  margin: 0 20px;
  height: 35px;
  background-color: var(--primary-blue);
  color: var(--white);
  border: var(--primary-blue) solid 2px;
  border-radius: 10px;
  padding: 2px 15px;
  min-width: 140px;
}

.SelectInput-button:hover {
  color: var(--primary-blue);
  background-color: var(--white);
}

p {
  color: var(--red) !important;
  font-size: 14px;
  margin-left: 15px;
  margin-bottom: 0;
}

.SelectInput-select {
  font-size: 15px;
  padding: 0px 15px;
  height: 35px;
  border: 1px solid var(--lightgrey);
  border-radius: 10px;
  width: 500px;
  margin: 0 5px;
}

select:focus-visible {
  outline: none;
  background-color: var(--lightgrey);
}

select.invalid {
  border: 1px solid var(--red);
}

option {
  background-color: #f5f5f5;
  font-size: 16px;
  font-weight: 500;
}
option:hover {
  background-color: #e1e1e1;
}
