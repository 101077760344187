.table-buttons-container {
    display: inline-flex;
  }

.buttons {
    padding: 8px 10px;
    margin: 0.6rem 10px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    transition: all 0.5s;
    border-radius: 4px;
    width: 100px;
    height: 40px;
}

.approve-btn {
    background-color: var(--white);
    color: var(--primary-blue);
    border: var(--primary-blue) solid 2px;
}
    
.approve-btn:hover {
    background-color: var(--primary-blue);
    color: var(--white);
}

.reject-btn {
    background-color: var(--white);
    color: var(--red);
    border: var(--red) solid 2px;
}

.reject-btn:hover {
    background-color: var(--red);
    color: var(--white);
}

.loading {
    opacity: 0.2;
}